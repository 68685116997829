import React, { useEffect } from "react";

import { connect } from "react-redux";
import {
  Shell,
  Stage,
  FeatureArtists,
  Video,
  Text,
  Button,
  BouncerContextHook,
  Card,
} from "@StixNext/mojito-ui";
import useCommonI18n from "../utils/i18nHelpers";
import HANS from "../assets/images/HANS.png";
import LAN from "../assets/images/LAN.png";
import JOSHUA from "../assets/images/JOSHUA.png";
import ANDREW from "../assets/images/ANDREW.png";
import main from "../assets/images/stage.png";
import { Column, Grid, Row } from "@StixNext/mojito-ui";
import * as actions from "../actions/Login";
import * as transActions from "../actions/Transaction";
import * as prodActions from "../actions/Products";
import Bravissimo from "../assets/images/bravissimo.jpg";
import BundleMerchandise768px from "../assets/images/BundleMerchandise-768px(w).jpg";
import BundleMerchandise320px from "../assets/images/BundleMerchandise-320px(w).jpeg";
import { TransactionStatus } from "../components/BuyProductAndTransaction";
import ticket from "../assets/images/ticket.svg";
import passport from "../assets/images/passport.svg";
import power from "../assets/images/power.svg";
import poster from "../assets/images/poster.svg";
import boxset from "../assets/images/boxset.svg";
import totebag from "../assets/images/totebag.svg";

function Merchandise(props) {
  return (
    <>
      <Shell.Body className={"css-merchandise"}>
        <Grid>
          <Row>
            <Column className="img_css">
              {/* <img
                className="img_laptop"
                src={BundleMerchandise768px}
                style={{ width: "100%", height: "100%" }}
              />
              <img
                className="img_mobile"
                src={BundleMerchandise768px}
                style={{ width: "100%", height: "100%" }}
              /> */}
              <Text as="h4" className="top_faq_title">
                <strong>{"Viewing Related | 觀看須知 "}</strong>
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "When can I view the single-view broadcast? 限定轉播何時能看到？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                You may access the single-view broadcast between 19 July 2021
                (Monday), 1000hrs to 2 August 2021 (Monday), 2359hrs. <br />
                您可於在 2021 年 7 月 19 日（星期一） 10:00 至 2021 年 8 月 2
                日（星期一） 23:59 之間進行觀看限定轉播。
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "How can I get the access details to the single-view broadcast? 我會如何獲得限定轉播登入鏈接詳情?"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                Your assigned unique link will be emailed to you. If you do not
                receive the email notification before 19 July 2021, kindly
                please check your junk/spam mail or contact us via
                feedback@sistic.com.sg or +65 6348 5555 for assistance.
                <br />
                Please add customerservice@sistic.com.sg to your safe sender
                list.
                <br />
                SISTIC 客服將通過電子郵件把您的獨特限定轉播鏈接細節發給您。
                <br />
                如果您在2021年7月19日之前未收到電子郵件，請查看您的垃圾郵件，或請通過電郵
                feedback@sistic.com.sg 或撥打 +65 6348 5555 與我們聯繫。
                <br />
                請把 customerservice@sistic.com.sg 加進您的安全收發名單中。
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "How will you ensure there will be no viewing or security issues? 我們如何確保觀看過程中不會再遇到同樣的技術問題？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                We have conducted full investigations and have identified that
                the incident was caused by the failure of the primary and backup
                ingestion servers of our Content Delivery Network provider
                during the live stream.
                <br />
                SISTIC had doubled the capacity of our infrastructure and
                conducted multiple stress and load tests to cater to the
                anticipated traffic during the event day. It was unfortunate
                that despite numerous tests before the event, the live stream
                was interrupted by a technical fault.
                <br />
                To prevent the same issue from happening and to ensure you have
                a good viewing experience during the single-view broadcast
                period, we have worked with the provider to improve the video
                delivery with a better Content Delivery Network.
                <br />
                我們進行了全面調查後，確認該事件是由我們供應商的內容傳輸網路故障造成的。主要和備份攝入伺服器在直播期間同時出現故障，導致直播中斷的情況。
                <br />
                以防止類似故障再發生，並確保您在限定轉播期間有良好的觀看體驗，我們已經提升了內容傳輸網路設施。
              </Text>

              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "How does the access link work? 鏈接如何操作？有什麼需要注意的事項？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_watch_para">
                All ticket holders will receive a unique link via email to access the full JJ Lin「Sanctuary Finale」Virtual Concert at a time of their convenience between 19 July 2021, 1000hrs (GMT +8) to 2 August 2021, 2359hrs (GMT +8). 
                <br />
                If you do not receive the email before 19 July 2021, please get in touch with the SISTIC Customer Service team at +65 6348 5555 or feedback@sistic.com.sg.
              </Text>
              <Text as="h4" className="faq_watch_title">
                <strong>{"To Note: "}</strong>
              </Text>
              <Text as="h4" className="faq_watch_para">
                <ul className="indentation">
                  <li>
                    Please log in using your SISTIC account with your assigned link.{" "}
                  </li>
                  <li>
                    Please note that after you log in, you must click "I Agree" to access the stream. The ticket will be deemed as utilised only after you click the "I Agree" button.{" "}
                  </li>
                  <li>
                    After log in, once the link is activated, you must complete the viewing in one session within 125 minutes. {" "}
                  </li>
                  <li>
                   If you experience internet connection issues during the session, try refreshing the page and you will be able to continue viewing from where you dropped off.<strong>Do not close your browser or log off your account as it will invalidate your pass and prevent you from continuing to watch. (Note: The time buffer for connectivity issues is 10 mins, please refrain from unnecessary refreshing)</strong>{" "}
                  </li>
                  <li>
                    <strong>No playbacks, scrubbing or pauses are allowed.</strong> This is to ensure compliance to copyright requirements.{" "}
                  </li>
                  <li>
                    Please proceed to log in only when you are ready to view, as <strong>you can only view the concert once.</strong> You may choose to view the full concert at a time of your convenience before 2 August 2021, 2359h (GMT +8). {" "}
                  </li>
                  <li>
                    Ticket holders who are not able to watch the Single View Broadcast during the stated period may share the link with a friend. Reselling your ticket is strictly prohibited and your link would be forfeited should you do so. {" "}
                  </li>
                  <li>
                    Any form of recording, screenshot and/or mirroring is strictly not allowed. Reproduction and distribution of content without the copyright owner's consent constitutes an infringement of copyright. If you are caught illegally streaming this content, you could be served legal sanction for copyright violation.{" "}
                  </li>
                </ul>
              </Text>
              <Text as="h4" className="faq_watch_para">
                {
                  "If you encounter any technical difficulties at any stage, please contact us feedback@sistic.com.sg or call us at +6348 5555 for assistance."
                }
                <br />
              </Text>

              <Text as="h4" className="faq_watch_title">
                <strong>{"觀看須知:"}</strong>
              </Text>
              <Text as="h4" className="faq_watch_para">
                <ul className="indentation">
                  <li>
                    您所收到的專屬鏈接，僅提供一次性的觀看，敬請留意。 {" "}
                  </li>
                  <li>點擊您的專屬鏈接，登入帳號密碼後，按下 "I Agree"（我同意）即可開始觀看，此步驟完成後視為專屬鏈結啟用時間點。影片長度為125分鐘，請確認您已準備好可以觀看整場演出後，再進行啟用的步驟。 {" "} </li>
                  <li>
                    觀看過程中，如果因網路收訊不佳，請試著「重新整理/刷新網頁」，將可從斷點延續觀看。切勿關閉瀏覽器或登出帳號，此舉將會導致影片失效，無法繼續觀看。 備註：不論刷新次數，操作時間總長不得超出十分鐘 {" "}
                  </li>
                  <li>
                    因應版權規範，影片將以順播模式進行，不得倒轉、快轉或暫停播放，敬請見諒。 {" "}
                  </li>
                  <li>
                    您可以在有效期間內的任何時段觀看，有效期間至2021 年8月2日晚上11:59 (GMT +8) 截止，逾期將會失去觀看權益。 {" "}
                  </li>
                  <li>
                    無法在有效期間前觀看演出的持票人，可以將鏈接轉贈給家人或朋友觀看。但轉賣您的專屬鏈接是嚴厲禁止的。違反者之專屬鏈接將會被註銷。 {" "}
                  </li>
                  <li>
                    演出內容嚴禁螢幕翻攝、錄製。未經著作權人的同意擅自發布、加工或重複使用內容的行為屬於侵權行為。若被發現非法播放或任何形式分享此演出，您將會受到侵犯著作權相關的法律制裁。 {" "}
                  </li>
                </ul>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "如有疑問，請電郵至 feedback@sistic.com.sg 或撥打 +65 6348 5555 與 SISTIC 客戶服務聯繫。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {"What are the supported browsers? 支持的播放系統有哪些？"}
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "System Requirements: Windows 7 or higher, Mac OS X 10.6 or higher "
                }
                <br />
                {
                  "Supported desktop browsers: Google Chrome 45+, Mozilla Firefox 49 + , Safari 10+ , Microsoft Edge 15+ , Internet Explorer 11 * ( requires Flash plugin to be installed ) "
                }
                <br />
                {"Supported mobile browsers: Chrome 45+, Safari 10+ (iOS), Airplay "}
                <br />
                {"Internet Connection: 25mbps download speed is recommended. "}
                <br />
                {
                  "支持的播放系統：Windows 7 或更新系統, Mac OS X 10.6 或更新系統 , Airplay"
                }
                <br />
                {
                  "瀏覽器：Google Chrome 45+, Mozilla Firefox 49+ , Safari 10+, Microsoft Edge 15+, Internet Explorer 11* (需安裝Flash插件) "
                }
                <br />
                {"手機：Chrome 45+, Safari 10+ (iOS) "}
                <br />
                {"網速：至少25mbps "}
                <br />
                <strong>
                  {
                    "Chromecast is not supported for the single-view broadcast. 限定轉播無法使用Chromecast。"
                  }
                </strong>
              </Text>
              <Text as="h4" className={"faq_para"}>
                <br />
                <strong>
                  {
                    "Am I able to use Chromecast? 我可以使用谷歌Chromecast 投影視頻嗎？"
                  }
                </strong>
                <br/>
                {
                  "No, Chromecast is not supported for the single-view broadcast. Android users who would like to connect their devices to their TV can use the screenshare option or via HDMI cable. "
                }
                <br />
                {
                  "不能。限定轉播無法使用Chromecast。Android 用戶可以通過螢幕共享或 HDMI 線做投影視頻。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Can I access the single-view broadcast after 2 August 2021? 我可以在8月2日之後觀看嗎？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "You will not be able to log in from the unique link after 2 August 2021, 2359hrs (GMT +8). We encourage you to utilise your access pass prior to the deadline. "
                }
                <br />
                {
                  "2021 年 8 月 2 日 (星期一) 2359 (GMT +8) 後，您將無法登入您的專屬鏈接。請在截止日期前使用。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "What happens if I accidentally close the browser while watching the single-view broadcast? 如果在觀看時，不小心關掉瀏覽器怎麼樣？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "As the access pass is validated once you are logged in, please ensure you do not switch devices or close the browser while viewing the live stream. "
                }
                <br />
                {
                  "專屬鏈接會在您登入帳號密碼，按下 'I Agree'（我同意）後啟動，切勿關閉瀏覽器或登出帳號，此舉將會導致影片失效，無法繼續觀看。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Can I pause or rewind during the single-view broadcast? 我可以在观看时拖動、暫停或回放嗎？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "No. Similar to the live stream, you will not be able to pause or rewind the stream once it is being played on the device. This is to ensure compliance to copyright requirements. "
                }
                <br />
                {
                  "如同7月10日線上演唱會一樣，觀看期間，因應版權規範，影片將以順播模式進行，不得倒轉、快轉或暫停播放，敬請見諒。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Do I need to use the same SISTIC account to log in? 我需要使用購票時相同的SISTIC帳戶登入嗎？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "No. Anyone with the unique link can log in using any SISTIC account. "
                }
                <br />
                {
                  "不需要。擁有專屬鏈接就可以通過任何一個 SISTIC 帳號登入觀看。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {"Can I watch this more than once? 我可以多次觀看嗎？"}
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {"No. This is to ensure compliance to copyright requirements. "}
                <br />
                {"不能， 因應版權規範所以無法多次觀看。"}
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "What happens if I cannot log in to access the single-view broadcast? 如果我無法登入觀看能怎麼辦？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "You may log in with your existing SISTIC account or create a new SISTIC account to log in. "
                }
                <br />
                {
                  "Please contact us at feedback@sistic.com.sg or call us at +6348 5555 for assistance if you face issues logging in. "
                }
                <br />
                {"您可以用現有的SISTIC帳戶登入，或重新登記一個帳戶登入。"}
                <br />
                {
                  "如果有疑問，請電郵 feedback@sistic.com.sg 或致電 +65 6348 5555。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Can I share the link with another person to view the single-view broadcast? 我可以與其他人共用鏈接嗎？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "Each ticket holder will be assigned with 1 unique link. Only 1 account and 1 device may be used. Ticket holders who are not able to watch the virtual concert during the stated period may share the link with a friend. Reselling your ticket is strictly prohibited and your link would be forfeited should you do so. "
                }
                <br />
                {
                  "每個持票人都會收到一個專屬鏈接。觀看時只能用一個設備登入一個帳號。無法在有效期間前觀看演出的持票人，可以將鏈接轉贈給家人或朋友觀看。但轉賣您的專屬鏈接是嚴厲禁止的。違反者之專屬鏈接將會被註銷。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "What should I do if I encounter technical issues while watching the single-view broadcast (i.e. no voice, pixelated screen)? 如果我在觀看時遇到技術問題（比如沒有聲音、圖元化螢幕），我該怎麼辦？ "
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "You may contact us at feedback@sistic.com.sg or call us at +6348 5555 for assistance if you encounter any issues during the broadcast. "
                }
                <br />
                {
                  "如果您需要技術上的支援，請聯絡 feedback@sistic.com.sg 或撥打+65 6348 5555 。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Can I switch browser or change to a different device? 我可以重新在另一个浏览器或装置观看吗?"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {"If you experience internet connection issues during the session, try refreshing the page and you will be able to continue viewing from where you dropped off. Never close your browser or log off your account as it will invalidate your pass and prevent you from continuing to watch it. "}
                <br />
                {"觀看過程中，如果因網路收訊不佳，請試著「重新整理/刷新網頁」，將可從斷點延續觀看。切勿關閉瀏覽器或登出帳號，此舉將會導致影片失效，無法繼續觀看。 "}
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Can I still activate my wristband during the single-view broadcast? 互動光芒手環還能在觀看時使用嗎？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "You will not be able to use the wristband during the broadcast. Kindly note that the refund amount includes the price of the wristband. "
                }
                <br />
                {
                  "互動光芒手環無法啟動，敬請見諒。我們已將互動光芒手環對應金額退款給您。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "What if I am unavailable during this period? 如果在此期間我不能抽出時間呢？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "The video is only available from 19 July 2021 - 2 August 2021. You may view it at any time at your convenience during this period. You will not be able to log in to the single-view broadcast after 2 August 2021, 2359hrs (GMT +8). We encourage you to utilise your access pass prior to the deadline. If you are unable to do so during the prescribed period, you may transfer the link to a friend. Please note that reselling of the unique link is strictly prohibited. "
                }
                <br />
                {
                  "限定轉播專屬鏈接的有效期為 2021年7月19日 早上10 點 至 2021年8月2日晚上 1159（GMT +8）。您可以在有效期間內的任何時段觀看，逾期將會失去觀看權益。"
                }
                <br />
                {
                  "我們鼓勵您在逾期前使用您的鏈接。無法在有效期間前觀看演出的持票人，可以將鏈接轉贈給家人或朋友觀看。但轉賣您的專屬鏈接是嚴厲禁止的。違反者之專屬鏈接將會被註銷。 "
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Is recording, screen shot, mirroring allowed? 屏幕攝像、錄製或鏡像模式會被限制嗎？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "Any form of recording, screenshot and/or mirroring is strictly not allowed. Reproduction and distribution of content without the copyright owner's consent constitutes an infringement of copyright. If you are caught illegally streaming this content, you could be served legal sanction for copyright violation. "
                }
                <br />
                {
                  "演出嚴禁屏幕攝像、錄製或鏡像模式。未經著作權人的同意擅自發布、加工或重複使用內容的行為屬於侵權行為。如果被發現非法播放或任何形式分享此演出，您將會受到侵犯著作權相關的法律制裁。 "
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Will subtitles be provided during the single-view broadcast? 演出是否會提供字幕？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {"Subtitles are not available for the broadcast. "}
                <br />
                {"限定轉播將不會提供任何字幕"}
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {"Refunds Related | 與退款有關"}
                  <br />
                  {"When will I receive my refunds? 我什麼時候会收到退款？"}
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "Ticket buyers will receive the refunds to the payment mode used to purchase the ticket, and not to your SISTIC account. You will receive your refund automatically before 10 August 2021. No further action is required from you. "
                }
                <br />
                {
                  "We are unable to refund to any other payment modes which were not used to purchase the ticket. "
                }
                <br />
                {
                  "If your credit card or payment mode has been cancelled, please contact us at feedback@sistic.com.sg or call us at +65 6348 5555 for assistance. "
                }
                <br />
                {
                  "購票者將在2021年8月10日前收到您的退款。退款將自動退回您購票時使用的信用卡或簽帳金融卡，而非您的SISTIC帳戶。 "
                }
                <br />
                {"我們無法將退款通過其他未在購票時使用的方式退回。"}
                <br />
                {
                  "如果您的信用卡或支付模式已取消或不再使用，請電郵 feedback@sistic.com.sg 或撥打 +65 6348 5555 與我們的客服聯繫。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {"How much refunds will I be receiving? 我將收到多少退款？"}
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "All Time Traveller Ticket holders will be refunded $41 (including booking fee). "
                }
                <br />
                {
                  "All other ticket tiers will be refunded $52 (including booking fee). "
                }
                <br />
                {
                  "The refund amount includes the access pass ticket, Finale Passport, Luminous Wristband and booking fee.   "
                }
                <br />
                {
                  "There shall be no refunds for the Sanctuary Tapestry Fabric Poster, Limited Edition Sanctuary Pictorial Box Set and Special Edition Sanctuary Eco Tote Bag which have already been mailed out by the event organiser. "
                }
                <br />
                {"標準時空旅人 - $41 "}
                <br />
                {"光芒時空旅人 / 進階時空旅人 / 頂級時空旅人 - $52 "}
                <br />
                {
                  "*退款金額包括電子門票 ，終點站時空通行證，互動光芒手環及手續費  "
                }
                <br />
                {
                  "退款將不包含已郵寄出去的物品的官方商品 (限量套裝 - 聖所掛上掛布海報, 聖所寫真卡片組, 聖所裝進帆布袋)。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Why have I not received my merchandise? 為什麼我沒有收到我的商品？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_para">
                {
                  "Due to the pandemic, delivery services to many destinations may be delayed or failed, subject to the local laws, regulations and policies. Customers who were affected by non-delivery due to pandemic restrictions have been contacted. If you still have not received your Resurgent ($68) or Sanctuary Complete Ticketing Bundle ($188) and have not been contacted, please email Isotope Productions at enquiry.isotopeproductions@gmail.com before 23 July 2021, Friday. Do also check your spam mail folder. "
                }
                <br />
                {
                  "因為疫情的不穩定性，寄送服務可能會因為寄送目的地的當地法律、法規和政策而延誤或無法送到。我們已經通過電郵聯絡所有受影響的購票者。如果你還未收到您購買的進階 ($68) 或頂級時空旅人配套 ($188) 或接獲任何通知，請在 7 月 23 日，星期五前通過 enquiry.isotopeproductions@gmail.com 與 Isotope Productions 聯絡。請查閱您的垃圾郵件箱。"
                }
              </Text>
              <Text as="h4" className="faq_title">
                <strong>
                  {
                    "Will I receive the refunds in my home currency? 我的退款會以什麼貨幣退回？"
                  }
                </strong>
              </Text>
              <Text as="h4" className="faq_watch_para">
                {
                  "All refunds are issued in Singapore Dollars (SGD). If your purchase was made with a credit card issued outside of Singapore, the amount credited will use the currency exchange rate at the time of the refund. Therefore, the amount credited may differ from the original transaction amount. "
                }
                <br />
                {
                  "所有退款以新幣（SGD）計算。 如果您購買時是以新加坡境外發行的信用卡或簽帳金融卡進行，計算的金額在退款時會以當天的貨幣匯率計算。因此，退款的金額可能會與原來交易時的金額不同。"
                }
              </Text>
            </Column>
          </Row>
        </Grid>
      </Shell.Body>
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.Login,
  transactionDetails: state.Transaction,
});

const mapDispatchToProps = (dispatch) => ({
  changeLoginValues: (f, e) => dispatch(actions.changeLoginValues(f, e)),
  getAuthToken: () => dispatch(actions.getAuthToken()),
  fetchTransaction: (data, buttonClicked) =>
    dispatch(transActions.fetchTransaction(data, buttonClicked)),
  changeTransactionValues: (f, e) =>
    dispatch(transActions.changeTransactionValues(f, e)),
  checkoutTransaction: (cardPayload, user) =>
    dispatch(transActions.checkoutTransaction(cardPayload, user)),
  fetchProducts: () => dispatch(prodActions.fetchProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchandise);
